<template>
  <div
    style="
      text-align: center;
      margin-top: 200px;
      font-size: 48px;
      color: #1890ff;
    "
  >
    {{name}}
  </div>
</template>
<script>
import { mapActions } from "vuex";
import config from "@/config"
import login from "@/api/system/login";
export default {
  name: "Home",
  data() {
    return {
      name:""
    };
  },
  beforeCreate() {
     login.isLogged().then((res) => {
      if (res.errorCode != this.$commons.RespCode.success) {
        this.clearAuth();
      }
    });
  },
  mounted() {
    this.$nextTick(function () {
       this.name=config.appName.replace("<br/>","");

    });
  },
  methods: {
    ...mapActions({
      clearAuth: "currentUser/clearAuth",
    }),
  },
};
</script>
<style lang="less">
</style>